.ru .ttr-title {
    font-family: "Roboto", sans-serif;
}

.ru .ttr-body {
    font-family: "Roboto", sans-serif;
}

.en .ttr-title {
    font-family: "Poppins", sans-serif;
}

.en .ttr-body {
    font-family: "Roboto", sans-serif;
}

.tr .ttr-title {
    font-family: "Poppins", sans-serif;
}

.tr .ttr-body {
    font-family: "Roboto", sans-serif;
}


