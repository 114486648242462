table {
  border: 1px solid #252828 !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  padding: 20px 20px !important;
}

thead {
  background-color: #2B3F75;
  border: 1px solid #2B3F75;;
  color: #fff;
}

.td {

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 135%;
  letter-spacing: 0.04em;
  color: #5A6061;
  min-height: 65px;
}

.user_data_title {
  min-width: 115px;
}

.mob {
  display: none;
}

@media screen and (max-width: 768px) {
  .web {
    display: none;
  }

  .mob {
    display: block;
  }

  .user_data_title {
    font-size: 12px;
    line-height: 135%;
    color: #5A6061;
  }

  .user_data_value {
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #252828;
  }

  .account_card {
    border: 1px solid #252828;
    width: 100%;
    height: 128px;
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 8px;
  }

  .card_row {
    display: flex;
    margin-bottom: 8px;
  }

  .acc_title {
    width: 42%;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #2B3F75;
  }

  .acc_value {
    width: auto;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
  }
}
