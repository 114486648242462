.main_wrapper {
  /* min-height: 100vh; */
}

.main_wrapper a {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  text-decoration: none;
  color: #252828;
  cursor: pointer;
}

.logo {
  padding: 35px 0px 32px 50px;
  height: 100%;
  background-color: #C8102E;
  height: 97px;
}

.logo a {
  font-weight: 900;
}

.logo span {
  margin-left: 5px;
}

.navbar {
  padding: 30px 50px 32px 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #C8102E;
}

.navbar button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding: 6px 15px;
  background: transparent;
  border: 1px solid #fff;
  cursor: pointer;
}

.navbar button:hover {
  border: 1px solid #2B3F75;
  background: #2B3F75;
  transition: 0.3s all ease;
}

.login {
  color: #fff;
}

.sidebar {
  padding: 50px 0px 20px 50px;
  height: 100%;
}

.sidebar .sidebarItem {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.sidebar a {
  margin-left: 15px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: 0.04em;
  color: #AEBABA;
}

.sidebarActive a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 135%;
  letter-spacing: 0.04em;
  color: #C8102E;
}

.sidebar a:hover{
  color: #2B3F75;
}

.sidebarItem:hover > div {
  background-color: #2B3F75;
}

.details {
  width: 18px;
  height: 18px;
  background-color: #AEBABA;
  display: block;
  mask-image: url("../../assets/img/account/Home.svg");
}

.detailsActive {
  background-color: #C8102E;
}

.statement {
  width: 18px;
  height: 18px;
  background-color: #AEBABA;
  display: block;
  mask-image: url("../../assets/img/account/Statement.svg");
}

.statementActive {
  background-color: #C8102E;
}

.swift {
  width: 18px;
  height: 18px;
  background-color: #AEBABA;
  display: block;
  mask-image: url("../../assets/img/account/Arrows.svg");
}

.swiftActive {
  background-color: #C8102E;
}

.exchange {
  width: 18px;
  height: 18px;
  background-color: #AEBABA;
  display: block;
  mask-image: url("../../assets/img/account/ArrowsExchange.svg");
}

.exchangeActive {
  background-color: #C8102E;
}

.internal {
  width: 18px;
  height: 18px;
  background-color: #AEBABA;
  display: block;
  mask-image: url("../../assets/img/account/ArrowCircle.svg");
}

.internalActive {
  background-color: #C8102E;
}

.opening {
  width: 18px;
  height: 18px;
  background-color: #AEBABA;
  display: block;
  mask-image: url("../../assets/img/account/lock.svg");
}

.openingActive {
  background-color: #C8102E;
}

.content {
  border-left: 1px solid #252828;
  min-height: calc(100vh - 416px);
}

.content .title {

  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 130%;
  letter-spacing: 0.1em;
  color: #2B3F75;
}

.navbarContainer_mob {
  display: none;
}

.mobile {
  display: none;
}

@media screen and (max-width: 768px) {

  .web {
    display: none;
  }

  .mobile {
    display: block;
  }

  .header_mob {
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #C8102E;
  }

  .navbar {
    margin-top: 7px;
    padding: 0 0 5px 0;
    background-color: transparent;
    align-items: flex-start;
    border-bottom: 1px solid #252828;
    justify-content: space-between;
  }

  .sidebarActive {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sidebarItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 62px;
  }

  .sidebarItem a {
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #C8102E;
  }

  .content {
    margin-top: 24px;
    border-left: none;
  }

  .content .title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    margin-bottom: 16px;
  }
}
