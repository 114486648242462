.contact_us_content {
    max-width: 1240px;
    margin: 0 auto;
    height: 812px;
    padding-top: 140px;
    display: flex;
    justify-content: space-between;
}

.main_bg {
    width: 100%;
    height: 712px;
}

.blur {
    position: absolute;
    top: 0;
    left: 0;
    background: #C8102E;
    height: 712px;
    width: 50%;
}

.main_text {
    margin-top: 267px;

    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 100px;
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    color: #FFFFFF;
    text-transform: uppercase;
    position: relative;
}

.main_text2_tr {

    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 180px;
    display: flex;
    align-items: center;
    color: #C8102E;
    position: absolute;
    top: 45px;
    right: -623px;
    text-transform: uppercase;
}

.main_text2 {

    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 180px;
    display: flex;
    align-items: center;
    color: #C8102E;
    position: absolute;
    top: 45px;
    right: -702px;
    text-transform: uppercase;
}

@media screen and (max-width: 1440px) {
    .main_text {

    }
}


.title {

    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 75px;
    display: flex;
    align-items: center;
    color: #2B3F75;
}

.left {
    width: 67%;
}

.right {
    width: 49%;
}

.welcome_block {
    margin-left: 100px;
    margin-top:36px ;
    width: 505px;
}

.welcome {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.1em;
    color: #252828;
    margin-bottom: 20px;
}

.contacts {

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    letter-spacing: 0.04em;
    color: #5A6061;
    margin-bottom: 10px;

}

.inputs_block {
    display: flex;
    margin-bottom: 20px;
}

.input_name {
    margin-bottom: 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_input {
    padding: 8px 10px;
    width: 190px;
    height: 38px;
    border: 1px solid #5A6061;
    outline: none;
    box-sizing: border-box;
}

.input_name_block {
    margin-right: 26px;
}

.my_textarea {
    width: 400px;
    height: 117px;
}

.my_btn {
    width: 190px;
    height: 39px;
    outline: none;
    cursor: pointer;
    background: #C8102E;
    border: 1px solid #C8102E;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #F6F7FA;
    justify-content: center;
    margin-top: 25px;
}

.my_btn:hover {
    background-color: #2B3F75;
    border-color: #2B3F75;
    transition: 0.3s all ease;
}

.description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;

}

.main_mobile {
    display: none;
}


@media screen and (max-width: 1440px) {
    .text {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%)
    }
}

@media screen and (max-width: 768px) {

    .main {
        display: none;
    }

    .main_mobile {
        display: block;
        background: url("../../assets/img/investor/bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 200px;
    }

    .main_text {

        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #C8102E;
        margin-right: 0;
        justify-content: center;
        gap: 10px;
        margin-top: 0;
        position: initial;
        padding-top: 100px;
    }

    .contact_us_content {
        flex-direction: column;
        height: auto;
        padding-top: 40px;
        margin: 0 16px;
    }

    .left {
        width: 100%;
    }

    .right {
        width: 100%;
    }

    .title {
        font-size: 28px;
        line-height: 130%;
        margin: 0;
    }

    .welcome_block {
        margin-left: 0;
        width: auto;
    }

    .welcome {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
    }

    .description {
        font-size: 12px;
        line-height: 135%;
        letter-spacing: 0.04em;
        margin-bottom: 32px;
    }

    .inputs_block {
        flex-direction: column;
    }

    .input_name_block {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .my_textarea {
        width: 288px;
    }

    .my_input {
        width: 288px;
    }

    .my_btn {
        width: 288px;
        font-size: 12px;
        line-height: 14px;
    }
}
