.myModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.myModalContent {
    padding: 25px 50px;
    background: white;
    min-width: 480px;
    min-height: 338px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.myModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_title {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #2B3F75;
    margin-bottom: 25px;
}

.popup_subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    margin-bottom: 22px;
}

.my_btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #C8102E;
    border: 1px solid #C8102E;
    width: 77px;
    height: 39px;
    justify-content: center;
}

.my_btn:hover {
    background: #2B3F75;
    border: 1px solid #2B3F75;
    transition: 0.3s all ease;
}

.flex {
    display: flex;
    justify-content: center;
}

.close_popup {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 62px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .myModalContent {
        padding: 0;
        background: white;
        min-width: initial;
        width: 288px;
        min-height: 237px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .close_popup {
        margin-bottom: 38px;
        margin-top: 16px;
        margin-right: 16px;
    }
}
