.login_page_wrapper {
    min-height: 100vh;
    display: flex;
}

.icon {
    stroke: #fff;
}

.left {
    background: url("../../assets/img/login/loginBG.png");
    background-size: cover;
    width: 50%;
    padding-left: 100px;
    position: relative;
}

.logo {
    margin-top: 40px;
    width: 243px;
}

.social_links_block {
    position: absolute;
    display: flex;
    bottom: 56px;
}

.social_links_block a {
    margin-right: 16px;
}

.right {
    width: 50%;
    padding-right: 100px;
    padding-left: 60px;
    position: relative;
    height: 100vh;
}

.btn_block {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.btn_block a {
    text-decoration: none;
}

.my_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #C8102E;
    padding: 6px 23px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #C8102E;
    background-color: transparent;
    cursor: pointer;
}

.my_btn:hover {
    border: 1px solid #2B3F75;
    color: #fff;
    background-color: #2B3F75;
    transition: 0.3s all ease;
}

.my_btn:hover .arr_back{
    background-color: #fff;
    transition: 0.3s all ease;
}

.arr_back {
    width: 22px;
    height: 13px;
    background-color: #C8102E;
    display: block;
    mask-image: url("../../assets/img/login/arrBack.svg");
    margin-right: 10px;
}

.form_block {
    min-height: calc( 100% - 80px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-bottom: 70px;
}

.pin_block {
    min-height: calc( 100% - 80px);
    box-sizing: border-box;
    padding-bottom: 70px;
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {

    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 75px;
    color: #2B3F75;
    margin-bottom: 45px;
}

.my_input {
    padding: 8px 10px;
    width: 190px;
    height: 38px;
    border: 1px solid #5A6061;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
}

.field_name {
    margin-bottom: 8px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.mb {
    margin-bottom: 20px;
}

.dont_have_account {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    margin: 0px 0px 20px 0px;
}

.bold {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: #252828;
}

.login_btn {
    background: #C8102E;
    border: 1px solid #C8102E;
    width: 190px;
    height: 39px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    color: #F6F7FA;
    cursor: pointer;
}

.login_btn:hover {
    background: #2B3F75;
    border: 1px solid #2B3F75;
    transition: 0.3s all ease;
}

.login_footer {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    height: 70px;
    margin-top: -70px;
}

.underline {
    text-decoration-line: underline;
    color: #5A6061;
    cursor: pointer;
}

.facebook {
    width: 28px;
    height: 28px;
    background-color: #5A6061;
    display: block;
    mask-image: url("../../assets/img/login/Facebook.svg");
}

.linkedin {
    width: 28px;
    height: 28px;
    background-color: #5A6061;
    display: block;
    mask-image: url("../../assets/img/login/LinkedIN.svg");
}

.whatsapp {
    width: 28px;
    height: 28px;
    background-color: #5A6061;
    display: block;
    mask-image: url("../../assets/img/login/WhatsApp.svg");
}

.mail {
    width: 28px;
    height: 28px;
    background-color: #5A6061;
    display: block;
    mask-image: url("../../assets/img/login/Mail.svg");
}

.insta {
    width: 28px;
    height: 28px;
    background-color: #5A6061;
    display: block;
    mask-image: url("../../assets/img/login/Insta.svg");
}

.signal {
    width: 28px;
    height: 28px;
    background-color: #5A6061;
    display: block;
    mask-image: url("../../assets/img/login/Signal.svg");
}

.telegram {
    width: 28px;
    height: 28px;
    background-color: #5A6061;
    display: block;
    mask-image: url("../../assets/img/login/Telegram.svg");
}

.header_container_mobile {
    display: none;
}

.error_msg {
    color: #5A6061;
    font-size: 16px;
    margin-bottom: 30px;
}

/*@media screen and (max-) {*/
/*    .login_footer {*/
/*        position: absolute;*/
/*        bottom: 40px;*/
/*        font-family: 'Roboto';*/
/*        font-style: normal;*/
/*        font-weight: 400;*/
/*        font-size: 12px;*/
/*        line-height: 135%;*/
/*        letter-spacing: 0.04em;*/
/*        color: #5A6061;*/
/*        padding: 20px 0px;*/
/*    }*/
/*}*/

@media screen and (max-width: 768px) {
    .btn_block {
        display: none;
    }

    .left {
        display: none;
    }

    .right {
        padding: 0;
        width: 100%;
    }

    .header_container_mobile {
        display: block;
        height: 56px;
        background-color: #C8102E;
        position: fixed;
        width: 100%;
    }

    .mob_logo_wrapper {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .logo_mob {
        width: 140px;
        height: 20px;
    }

    .arr_back_mob {
        width: 22px;
        height: 13px;
        background-color: #000;
        display: block;
        mask-image: url("../../assets/img/login/arrBack.svg");
        margin-right: 10px;
        position: absolute;
        top: 22px;
        right: 16px;
    }

    .form_block {
        margin-top: 107px;
        margin-left: 16px;
        margin-right: 16px;
        justify-content: center;
        padding-bottom: 0;
        min-height: initial;
        height: auto;
    }

    .title {
        font-size: 28px;
        text-align: center;
    }

    .login_footer {
        display: none;
    }

    .my_input {
        width: 288px;
    }

    .login_btn {
        width: 288px;
    }
}
