.homeWrapper {
}

.homeContent {

}

.main {
    /*height: 900px;*/
}

.main_bg {
    width: 100%;
    height: 100vh;
}

.blur {
    position: absolute;
    top: 0;
    left: 0;
    background: #C8102E;
    height: 100vh;
    width: 50%;
}

.name {
    margin-right: 30px;
    margin-top: 230px;
    /**/
    font-style: normal;
    font-weight: 700;
    font-size: 74px;
    line-height: 74px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    color: #FFFFFF;
}

.name2 {
    position: absolute;
    top: 46%;
    left: 52%;

    font-style: normal;
    font-weight: 700;
    font-size: 74px;
    line-height: 74px;
    display: flex;
    align-items: center;
    color: #C8102E;
}

@media screen and (max-width: 768px) {
    .blur {
        display: none;
    }
}

.my_btn {
    background: #F6F7FA;
    border: 1px solid #F6F7FA;
    padding: 10px 42px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #2B3F75;
}

.my_btn:hover {
    color: #fff;
    background-color: #2B3F75;
    border-color: #2B3F75;
    transition: 0.3s all ease;
}

.desc_block {
    position: absolute;
    bottom: 10px;
    left: 114px;
    max-width: 288px;
}

.desc_title {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.1em;
    color: #F6F7FA;
    margin-bottom: 14px;
}

.desc_subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #F6F7FA;
    margin-bottom: 25px;
}

.banking {
    height: 850px;
    display: flex;
    justify-content: flex-end;
}

.e_money {
    height: 650px;
    display: flex;
    justify-content: flex-end;
}

.banking_content {
    width: 100%;
    padding-left: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.banking_left {

    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 75px;
    color: #2B3F75;
    width: 40%;
    margin-top: 96px;
}

.banking_right {
    width: 60%;
    margin-top: 101px;
}

@media only screen and (min-width: 1441px) {
    .banking_right {
        width: 56%;
    }
    .banking_content {
    }
}

.dropdown_menu1 {
    display: flex;
    border: 1px solid #252828;
    border-right: none;
    margin-bottom: 40px;
    cursor:pointer;
    color: #252828;
}

.dropdown_menu2 {
    display: flex;
    border: 1px solid #252828;
    border-right: none;
    margin-bottom: 40px;
    cursor:pointer;
}

.dropdown_menu3 {
    display: flex;
    border: 1px solid #252828;
    border-right: none;
    margin-bottom: 40px;
    cursor:pointer;
}

.dropdown_menu4 {
    display: flex;
    border: 1px solid #252828;
    border-right: none;
    margin-bottom: 40px;
    cursor:pointer;
}

.dropdown_menu_num {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.1em;
    width: 83px;
    height: 72px;
    border-right: 1px solid #252828;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown_menu_title {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.1em;
    padding: 17px 0 16px 20px;
}

.dropdown_content1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    width: 609px;
    margin-left: 100px;
    padding-bottom: 20px;
}

.dropdown_menu1 + .dropdown_content1 {
    display: none;
}

.dropdown_menu1:hover {
    background-color: #2B3F75;
    border: 1px solid #fff;
    color: #fff;
}

.dropdown_menu1:hover .dropdown_menu_num {
    border-right: 1px solid #fff;
}

.dropdown_menu1:hover + .dropdown_content1 {
    display: block;
    opacity: 0;
    animation: ani .5s forwards;
}


.dropdown_content2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    width: 609px;
    margin-left: 100px;
    padding-bottom: 20px;
}

.dropdown_menu2 + .dropdown_content2 {
    display: none;
}

.dropdown_menu2:hover + .dropdown_content2 {
    display: block;
    opacity: 0;
    animation: ani .5s forwards;
}

.dropdown_menu2:hover {
    background-color: #2B3F75;
    border: 1px solid #fff;
    color: #fff;
}

.dropdown_menu2:hover .dropdown_menu_num {
    border-right: 1px solid #fff;
}


.dropdown_content3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    width: 609px;
    margin-left: 100px;
    padding-bottom: 20px;
}

.dropdown_menu3 + .dropdown_content3 {
    display: none;
}

.dropdown_menu3:hover {
    background-color: #2B3F75;
    border: 1px solid #fff;
    color: #fff;
}

.dropdown_menu3:hover .dropdown_menu_num {
    border-right: 1px solid #fff;
}

.dropdown_menu3:hover + .dropdown_content3 {
    display: block;
    opacity: 0;
    animation: ani .5s forwards;
}


.dropdown_content4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    width: 609px;
    margin-left: 100px;
    padding-bottom: 20px;
}

.dropdown_menu4 + .dropdown_content4 {
    display: none;
}

.dropdown_menu4:hover {
    background-color: #2B3F75;
    border: 1px solid #fff;
    color: #fff;
}

.dropdown_menu4:hover .dropdown_menu_num {
    border-right: 1px solid #fff;
}

.dropdown_menu4:hover + .dropdown_content4 {
    display: block;
    opacity: 0;
    animation: ani .5s forwards;
}

@keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.saving_account_wrapper {
    display: flex;
    margin-bottom: 50px;
}

.img_block {
    position: relative;
    width: 50%;
}

.img_block img {
    width: 100%;
    height: 486px;
}

@media only screen and (max-width: 1440px) {
    .img_block img {
        height: 470px;
    }
}

.saving_title {

    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 75px;
    display: flex;
    align-items: center;
    color: #2B3F75;
    margin-bottom: 36px;
    width: 300px;
}

.saving_text_block {
    padding-left: 20px;
    padding-top: 48px;
}

.saving_subtitle {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.1em;
    color: #252828;
    padding-bottom: 20px;
    width: 518px;
}

.saving_desc {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    max-width: 505px;
}

.main_screen_mobile {
    display: none;
}

.img_block_mob {
    display: none;
}

@media screen and (max-width: 1024px) {
    .name {
        margin-top: 107px;
        font-size: 48px;
        line-height: 48px;
    }

    .name2 {
        font-size: 48px;
        line-height: 48px;
        top: 36%;
    }

    .desc_title {
        font-size: 17px;
        line-height: 17px;
    }

    .desc_subtitle {
        font-size: 12px;
    }

    .my_btn {
        font-size: 12px;
    }

    .dropdown_content1 {
        width: auto;
    }

    .dropdown_content2 {
        width: auto;
    }

    .dropdown_content3 {
        width: auto;
    }

    .desc_block {
        bottom: 41px;
    }

    .banking_left {
        font-size: 48px;
    }

    .saving_title {
        font-size: 48px;
        line-height: 48px;
    }

}

@media screen and (max-width: 768px) {
    .main {
        display: none;
    }

    .main_screen_mobile {
        display: block;
        background: url("../../assets/img/main_bg_mobile.png");
        background-size: cover;
        height: 568px;
        width: auto;

    }

    .banking_content {
        width: auto;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: initial;
    }

    .banking_left {
        width: 100%;
        margin-top: 0;
    }

    .banking_right {
        width: 100%;
    }

    .name_mobile_block {
        display: flex;
        padding-top: 100px;
        flex-direction: column;
        align-items: center;
    }

    .name_mobile {

        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 38px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #C8102E;
    }

    .mobile_title {
        margin: 36px 16px 14px;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #C8102E;
    }

    .mobile_subtitle {
        margin: 0 16px 30px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        text-align: center;
        letter-spacing: 0.04em;
        color: #C8102E;
    }

    .my_btn_mobile {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #2B3F75;
        padding: 10px 42px;
        border: none;
        margin: 0 auto;
        background-color: #fff;
    }

    .banking_left {

        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #2B3F75;
        margin-top: 64px;
        margin-left: 16px;
        margin-bottom: 32px;
        width: auto;
    }

    .banking_right {
        margin-top: 0;
        max-width: 320px;
    }

    .dropdown_menu_num {

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: #252828;
        max-width: 54px;
        height: auto;
    }

    .dropdown_menu_title {

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: #252828;
    }

    .dropdown_menu1 {
        margin: 0 16px 12px;
        border-right: 1px solid #252828;
    }

    .dropdown_menu2 {
        margin: 0 16px 12px;
        border-right: 1px solid #252828;
    }

    .dropdown_menu3 {
        margin: 0 16px 12px;
        border-right: 1px solid #252828;
    }

    .dropdown_menu4 {
        margin: 0 16px 12px;
        border-right: 1px solid #252828;
    }

    .dropdown_content1 {
        width: 288px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        letter-spacing: 0.04em;
        color: #5A6061;
        margin-left: 16px;
    }

    .dropdown_content2 {
        width: 288px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        letter-spacing: 0.04em;
        color: #5A6061;
        margin-left: 16px;
    }

    .dropdown_content3 {
        width: 288px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        letter-spacing: 0.04em;
        color: #5A6061;
        margin-left: 16px;
    }

    .dropdown_content4 {
        width: 288px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        letter-spacing: 0.04em;
        color: #5A6061;
        margin-left: 16px;
    }

    .dropdown_menu1:hover {
        color: #fff;
        background-color: #C8102E;
    }

    .dropdown_menu2:hover {
        color: #fff;
        background-color: #C8102E;
    }

    .dropdown_menu3:hover {
        color: #fff;
        background-color: #FF585B;
    }

    .banking {
        height: auto;
    }

    .e_money {
        height: auto;
    }

    .img_block img {
       display: none;
    }

    .img_block_mob {
        display: block;
    }

    .img_block_mob img {
        width: 100%;
    }

    .saving_account_wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 64px;
        margin-bottom: 16px;
    }

    .saving_title {

        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #2B3F75;
    }

    .saving_text_block {
        padding-top: 16px;
    }

    .saving_subtitle {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        width: auto;
    }

    .saving_desc {
        font-size: 12px;
        line-height: 135%;
        letter-spacing: 0.04em;
    }

}
