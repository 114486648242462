.contact_us_content {
    max-width: 1240px;
    margin: 0 auto;
    height: 880px;
    padding-top: 140px;
    display: flex;
    justify-content: space-between;
}

.main_bg {
    width: 100%;
    height: 712px;
}

.blur {
    position: absolute;
    top: 0;
    left: 0;
    background: #C8102E;
    height: 712px;
    width: 50%;
}

.main_text {
    margin-top: 310px;
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 100px;
    display: flex;
    color: #FFFFFF;
    text-transform: uppercase;
    justify-content: flex-end;
    margin-right: 30px;
    position: relative;
}

.main_text_tur {
    margin-top: 310px;
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 100px;
    display: flex;
    color: #FFFFFF;
    text-transform: uppercase;
    justify-content: flex-end;
    margin-right: 30px;
    position: relative;
}

.main_text_ru {
    margin-top: 310px;
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 100px;
    display: flex;
    color: #FFFFFF;
    text-transform: uppercase;
    justify-content: flex-end;
    margin-right: 30px;
    position: relative;
}

.main_text2_tur {

    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 180px;
    display: flex;
    align-items: center;
    color: #C8102E;
    text-transform: uppercase;
    position: absolute;
    right: -481px;
    top: 41px;
}

.main_text2 {

    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 180px;
    display: flex;
    align-items: center;
    color: #C8102E;
    text-transform: uppercase;
    position: absolute;
    right: -211px;
    top: 41px;
}

.main_text2_ru {

    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 180px;
    display: flex;
    align-items: center;
    color: #C8102E;
    text-transform: uppercase;
    position: absolute;
    right: -700px;
    top: 41px;
}

@media screen and (max-width: 1440px) {
    .main_text {
        position: relative;
    }

    .main_text2 {
        right: -211px;
        top: 41px;
    }
}

.title {

    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 75px;
    display: flex;
    align-items: center;
    color: #2B3F75;
}

.left {
    width: 41%;
}

.right {
    width: 49%;
}

.welcome_block {
    margin-left: 100px;
    margin-top:36px ;
}

.welcome {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.1em;
    color: #252828;
    margin-bottom: 20px;
}

.contacts {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    letter-spacing: 0.04em;
    color: #5A6061;
    margin-bottom: 10px;
}

.cyprus_office {
    margin-top: 25px;
}

.cyprus_title {
    margin-bottom: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #252828;
}

.img {
    margin-right: 10px;
}

.social_block {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.social_block a {
    width: 136px;
    margin-bottom: 20px;
}

.social_link {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.social_link_text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: #5A6061;
}

.inputs_block {
    display: flex;
    margin-bottom: 20px;
}

.input_name {
    margin-bottom: 7px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_input {
    padding: 8px 10px;
    width: 190px;
    height: 38px;
    border: 1px solid #5A6061;
    outline: none;
    box-sizing: border-box;
}

.input_name_block {
    margin-right: 26px;
}

.my_textarea {
    width: 620px;
    height: 117px;
}

.my_btn {
    width: 190px;
    height: 39px;
    outline: none;
    cursor: pointer;
    background: #C8102E;
    border: 1px solid #C8102E;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #F6F7FA;
    justify-content: center;
    margin-top: 25px;
}

.my_btn:hover {
    background-color: #2B3F75;
    border-color: #2B3F75;
    transition: 0.3s all ease;
}

.main_mobile {
    display: none;
}

@media screen and (max-width: 1024px) {
    .main_text {
        font-size: 48px;
        margin-top: 172px;
    }

    .main_text2 {
        font-size: 48px;
        right: -127px;
        top: 5px;
    }

    .main_bg {
        height: 100vh;
    }

    .blur {
        height: 100vh;
    }

    .title {
        font-size: 48px;
        line-height: 48px;
    }

    .welcome {
        font-size: 20px;
        line-height: 20px;
    }

    .main_text_tur {
        margin-top: 170px;
    }

    .my_textarea {
        width: 100%;
        margin-right: 20px;
    }

    .inputs_block {
        flex-wrap: wrap;
    }

    .mail {
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px) {

    .main {
        display: none;
    }

    .main_mobile {
        display: block;
        background: url("../../assets/img/contact/contactBg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 200px;
    }

    .contact_us_content {
        max-width: initial;
        margin: 0 auto;
        height: auto;
        padding-top: 140px;
        display: flex;
        flex-direction: column;
    }

    .main_bg {
        height: auto;
        margin-top: 56px;
    }

    .blur {
        background: transparent;
        height: max-content;
        width: 100%;
    }

    .main_text {

        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #C8102E;
        margin-right: 0;
        justify-content: center;
        gap: 10px;
        margin-top: 0;
        position: initial;
        padding-top: 100px;
    }

    .contact_us_content {
        flex-direction: column;
        padding-top: 40px;
        margin: 0 16px;
    }

    .left {
        width: 100%;
    }

    .right {
        width: 100%;
    }

    .inputs_block {
        flex-direction: column;
    }

    .my_textarea {
        width: 288px;
        height: 117px;
        margin-right: 0;
    }

    .my_input {
        width: 288px;
    }

    .welcome_block {
        margin-left: 0;
    }

    .title {
        font-size: 28px;
        line-height: 130%;
    }

    .welcome_block {
        margin-top: 24px;
    }

    .welcome {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        text-transform: capitalize;
    }

    .right {
        margin-top: 32px;
    }

    .input_name_block {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .my_btn {
        width: 288px;
        font-size: 12px;
        line-height: 14px;
    }

    .social_block {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        justify-content: space-between;
    }
}
