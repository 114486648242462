.footerWrapper {
  display: flex;
}

.left {
  width: 50%;
  background-position: center;
  background: url("../../assets/img/footerBG.png") no-repeat;
  background-size:cover;
}

.right {
  width: 50%;
  background: #252828;
  padding-top: 53px;
  padding-left: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.allRightsBlock {
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.04em;
  color: #f6f7fa;
  margin-top: 50px;
}

.phoneNumberBlock {
  display: flex;
  margin-left: 60px;
}

.line {
  text-decoration-line: underline;
  cursor: pointer;
  color: #f6f7fa;
}

.logoBlock {
  margin-right: 60px;
  margin-bottom: 40px;
}

.rightContent {
  display: flex;
}

.links_wrapper {
  display: flex;
  margin-left: 35px;
}

.linksBlock {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  margin-top: 6px;
}

.link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  margin-bottom: 10px;
}

.link:hover {
  color: #2B3F75;
  cursor: pointer;
}

.active {
  text-decoration: none;
}

.loginButtonBlock {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.loginButtonBlock a {
  text-decoration: none;
}

.login {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-left: 8px;
}

.signUpButton {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding: 6px 7px;
  background: transparent;
  border: 1px solid #fff;
}

.signUpButton:hover {
  background: #2B3F75;
  border: 1px solid #2B3F75;
  transition: 0.3s all ease;
}

.socialContainer {
  margin-top: 30px;
  width: 236px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 20px;
}

.socialBlock {
  margin-left: 100px;
}

.socialLink {
  margin-right: 20px;
}

.socialLink:last-child {
  margin-right: 0;
}

.mobile_footer {
  display: none;
}

.socialRow {
  margin-bottom: 20px;
}


@media screen and (max-width: 1024px) {
  .mobile_footer {
    display: block;
    background: url("../../assets/img/footer_mobile_bg.png");
    background-size: cover;
    background-position: center;
    width: auto;
    height: 150px;
    margin-top: 24px;
  }

  .footerWrapper {
    display: none;
  }

  .social_links_block {
    display: flex;
    justify-content: center;
    padding-top: 44px;
  }

  .social_links_block a {
    margin-right: 16px;
  }

  .facebook {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/img/login/Facebook.svg");
  }

  .linkedin {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/img/login/LinkedIN.svg");
  }

  .whatsapp {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/img/login/WhatsApp.svg");
  }

  .mail {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/img/login/Mail.svg");
  }

  .insta {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/img/login/Insta.svg");
  }

  .signal {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/img/login/Signal.svg");
  }

  .telegram {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/img/login/Telegram.svg");
  }

  .allRightsBlock {
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    display: block;
    margin: 0 auto;
    width: 265px;
    padding-top: 28px;
    text-align: center;
  }

  .line {
    color: #5A6061;
  }

}
