.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 37px;
}

.fields {
    flex-direction: column;
}

.fields__input {
    width: 230px;
    background: #fff;
    padding: 15px;
    margin-bottom: 32px;
    height: 38px;
    border: 1px solid #252828;
}

.spanBlock {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
    margin-bottom: 8px;
}

.my_btn {
    background: #C8102E;
    border: 1px solid #C8102E;
    padding: 10px 60px;
    margin-top: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.my_btn:hover {
    background: #2B3F75;
    border: 1px solid #2B3F75;
    transition: 0.3s all ease;
}

.agree {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.terms {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #252828;
}

.checkbox {
    margin-right: 12px;
}

@media screen and (max-width: 768px) {
    .row {
        flex-direction: column;
        gap: 0;
    }

    .text_center {
        text-align: center;
    }

    .my_btn {
        width: 100%;
        justify-content: center;
    }

    .fields__input {
        width: calc(100vw - 32px);
    }
}

