.fields {
    width: 100%;
}
.spanBlock {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
    margin-bottom: 8px;
}
@media screen and (max-width: 1023px) {
    .spanBlock {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 145%;
        display: flex;
        align-items: center;
        color: #5f5f5f;
    }
}
.titleBlock {
    margin-top: 25px;
}
.international-transfer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 40px;
    margin: 15px 0;
    min-width: 990px;
}
@media screen and (max-width: 1394px) {
    .international-transfer-wrapper {
        align-items: center;
    }
}
@media screen and (max-width: 900px) {
    .international-transfer-wrapper {
        width: 100%;
        min-width: auto;
        padding: 16px;
        margin: 24px 0;
    }
}
.inputsWrapper {
    display: flex;
}
@media screen and (max-width: 950px) {
    .inputsWrapper {
        flex-direction: column;
    }
}
.inputsColumn {
    margin-right: 35px;
}
@media screen and (max-width: 950px) {
    .inputsColumn {
        margin: 0 auto;
        width: 100%;
    }
}
.transactionsDetails {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.1em;
    color: #2B3F75;
    margin-bottom: 40px;
    margin-top: 50px;
}
@media screen and (max-width: 768px) {
    .transactionsDetails {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.05em;
        color: #312f2d;
        margin-bottom: 24px;
        text-align: left;
    }
}
.description {
    width: 36%;
    text-align: left;
}
.fields {
    flex-direction: column;
}

.fields__input {
    width: 190px;
    background: #fff;
    padding: 15px;
    margin-bottom: 25px;
    height: 38px;
    border: 1px solid #252828;
}
@media screen and (max-width: 1023px) {
    .fields__input {
        height: 40px;
        margin-bottom: 16px;
    }
}
.el-radio-group {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.el-radio {
    margin-bottom: 15px;
}
.el-checkbox {
    margin-bottom: 15px;
}
.errorMessage {
    color: #cc3f55;
}
.button-wrapper {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 10px;
}
.el-button--primary {
    color: #fff;
    background: #2e4d4f;
    border: 2px solid #2e4d4f;
    width: 90%;
}
.btn-block {
    margin-top: 25px;
    width: 58%;
}
@media screen and (max-width: 600px) {
    .btn-block {
        margin: 0;
        width: 100%;
    }

    .fields__input {
        width: 100%;
    }

    .inputsColumn {
        margin-right: 0;
    }
}
.block-input span {
    width: 200px;
    text-align: left;
}
@media screen and (max-width: 1068px) {
    .content {
        text-align: center;
    }
    .el-button {
        font-size: 12px;
    }
    .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
        width: 90%;
    }
}
.el-input__inner {
    height: 50px;
}
@media screen and (max-width: 1023px) {
    .el-input__inner {
        height: 40px;
    }
}
.my_btn {
    background: #C8102E;
    border: 1px solid #C8102E;
    padding: 10px 60px;
    margin-top: 50px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.my_btn:hover {
    background: #2B3F75;
    border: 1px solid #2B3F75;
    transition: 0.3s all ease;
}

@media screen and (max-width: 1023px) {
    .my_btn {
        margin-top: 24px;
        width: 100%;
    }
}

.agree {

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.terms {

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #252828;
}

.checkbox {
    margin-right: 12px;
}
