.wrapper {
    margin-top: 140px;
}

.bg_img {
    background-size: cover;
    position: relative;
}

.debit_content {
    display: flex;
    justify-content: space-between;
    padding: 100px;
}

.left_side {
    width: 45%;
}

.right_side {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.my_gif {
    width: 543px;
    height: 396px;
}

.title {

    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 75px;
    display: flex;
    align-items: center;
    color: #2B3F75;
    margin-bottom: 37px;
    text-transform: capitalize;
}

.subtitle {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #252828;
    margin-bottom: 21px;
}

.text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_btn {
    width: 190px;
    height: 39px;
    background: #C8102E;
    border: 1px solid #C8102E;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    justify-content: center;
}

.my_btn:hover {
    color: #fff;
    background-color: #2B3F75;
    border-color: #2B3F75;
    transition: 0.3s all ease;
}

.visa {

}

.union {
    margin-right: 50px;
}

@media screen and (max-width: 1024px) {
    .right_side {
        display: none;
    }

    .left_side {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .right_side {
        display: none;
    }

    .wrapper {
        margin-top: 64px;
    }

    .title {
        font-size: 28px;
        line-height: 130%;
    }

    .debit_content {
        padding: 48px 16px;
        display: block;
    }

    .left_side {
        width: 100%;
    }

    .subtitle {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        margin-bottom: 26px;
    }

    .text {
        font-size: 12px;
        line-height: 135%;
        letter-spacing: 0.04em;
        margin-bottom: 26px;
    }

    .my_btn {
        width: 288px;
        font-size: 12px;
        line-height: 14px;
    }
}
