.App {
  height: 100vh;
  position: relative;
}

.mainContent {
  margin: 0 auto;
}

.arrow_up {
  position: fixed;
  bottom: 100px;
  right: 43px;
  cursor: pointer;
}

.ttr {
  
}

.ru {

}
