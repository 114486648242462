.values {

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 145%;
    color: #5A6061;
    min-height: 78px;
}

.canceled {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 145%;
    color: #5A6061;
    min-height: 78px;
}

.confirmed {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 145%;
    color: #b00101;
    min-height: 78px;
}

.my_select {
    width: fit-content;
    height: 38px;
    outline: none;
    margin-bottom: 51px;
}

.select_name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
    margin-bottom: 8px;
}

.option {
    min-height: 38px;
}

.no_transactions {
    position: sticky;
    left: 60%;
    margin: 20px 20px 78px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.table_mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .table_web {
        display: none;
    }

    .table_mobile {
        display: block;
    }

    .account_card {
        border: 1px solid #252828;
        width: 288px;
        height: auto;
        margin-bottom: 16px;
        margin-top: 16px;
        padding: 8px;
    }

    .card_row {
        display: flex;
        margin-bottom: 8px;
    }

    .acc_title {
        width: 51%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 145%;
        color: #2B3F75;
    }

    .acc_value {
        width: auto;
        font-size: 12px;
        line-height: 135%;
        letter-spacing: 0.04em;
        color: #5A6061;
    }
}

